  // ariadne-qa
  export const environment = {
    production: false,
    serverUrl: 'https://2xc4za48gf.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'ariadne-qa-upload',
    userPool: {
      UserPoolId: 'us-east-2_mHOzMHQRy',
      ClientId: '3afb76kne1lfc1t4p1c4hddgmv',
      region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:944f282c-6bde-49c2-861d-04d1c92ab1cd',
    buckets: {
      'us-east-2': 'ariadne-qa-upload'
    },
    defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId:'us-east-2:944f282c-6bde-49c2-861d-04d1c92ab1cd', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_mHOzMHQRy', //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '3afb76kne1lfc1t4p1c4hddgmv', //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'ariadne-qa-upload', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      }
    }
  };

const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_mHOzMHQRy',
  'aws_user_pools_web_client_id': '3afb76kne1lfc1t4p1c4hddgmv',
  'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;